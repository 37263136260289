import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import SolarSystem from "../components/solar-system/solar-system"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <SolarSystem hidePlanets></SolarSystem>
    <div
      className="404-content"
      style={{
        color: "#ffffff",
        textAlign: "center",
        padding: "6rem 1rem",
      }}
    >
      <h1 className="h2" style={{ marginBottom: "1rem" }}>
        Oh, no! You seem to have stumbled into the void.
      </h1>
      <h2 className="h4">
        Don't worry, it happens to all of us, one way or another.
      </h2>
      <p>
        Let's go to the{" "}
        <Link to="/" className="link_text" style={{ color: "#ffffff" }}>
          Home page
        </Link>{" "}
        or explore the links listed in the header or footer.
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
